<template>
    <b-card class="mt-2">
      <b-row v-if="isError" class="justify-content-center mt-2">
        <b-col lg="10">
          <b-alert variant="danger" show dismissible>
            Opps .. Something is wrong
          </b-alert>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <b-form-input placeholder="Type Here to Search" type="search" v-model="narrator_search" @input="onSearch"
            v-on:keyup.enter="onSearch(narrator_search)"></b-form-input>
          <small v-if="typing"> <em> typing ... </em> </small>
        </b-col>
      </b-row>
      <div class="row justify-content-end m-1">
        <span class="pr-2">
          <router-link class="btn btn-sm btn-dark" :to="NarratorCreatePath">
            <i class="fa fa-plus"></i>
          </router-link>
        </span>
        <span>
          <button class="btn btn-sm btn-dark" @click="refreshPage">
            <i class="fa fa-refresh"></i>
          </button>
        </span>
        <label for="" class="col-sm-auto">Count </label>
        <strong class="col-sm-auto">{{ totalRows | toLocalStringID }}</strong>
      </div>
      <b-table sticky-header="500px" responsive="xl" hover :items="items" :busy="isLoading" :isLoading="isLoading"
        :fields="fields" show-empty>
        <template #cell(No)="row">
          {{ (currentPage - 1) * perPage + (row.index + 1) }}
        </template>
        <template #cell(name)="row">
          <div style="width: 300px;">
            <router-link :to="{ ...NarratorUpdatePath, params: { id: row.item.id } }">
              {{ row.item.name }}
            </router-link>
          </div>
        </template>
        <template #cell(slug)="row">
          <div style="width: 200px;">
            {{ row.item.slug }}
          </div>
        </template>
        <template #cell(academic_title)="row">
          <div  v-if = "!row.item.academic_title||row.item.academic_title != 'null'" style="width: 200px;">
            {{ row.item.academic_title }}
          </div>
          <div v-else>
            No Academic Title
          </div>
        </template>
        <template v-slot:table-busy>
          <div class="text-center text-dark my-2">
            <b-spinner class="align-middle" variant="dark" type="grow" label="Loading ..">
            </b-spinner>
            Loading ..
          </div>
        </template>
      </b-table>
      <b-row class="justify-content-start ml-1">
        <b-col lg="3">
          <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" aria-controls="my-table"
            @change="onClickPage" />
        </b-col>
      </b-row>
    </b-card>
  </template>
  
  <script>
  import { mapState, mapActions } from "vuex";
  import moment from "moment";
  import { NarratorCreatePath, NarratorUpdatePath } from "../../router/content";
  export default {
    name: "Narrator",
    data() {
      return {
        currentPage: 1,
        perPage: 10,
        narrator_search: null,
        typing: false,
        fields: [
          { key: 'No', label: 'No' },
          { key: 'name', label: 'Narrator Name' },
          { key: 'slug', label: 'Slug' },
          { key: 'first_name', label: 'First Name' },
          { key: 'last_name', label: 'Last Name' },
          { key: 'academic_title', label: ' Academic Title ' },
          {
            key: 'created',
            label: 'Created At',
            class: 'text-center text-nowrap',
            formatter: (value) => {
              return moment(value).format('DD MMM YYYY')
            }
          },
          {
            key: 'modified',
            label: 'Modified At',
            class: 'text-center text-nowrap',
            formatter: (value) => {
              return moment(value).format('DD MMM YYYY')
            }
          },
          // { key: 'action', label: 'Action' },
        ],
        NarratorCreatePath,
        NarratorUpdatePath,
      };
    },
    computed: {
      ...mapState({
        isLoading: (state) => state.narrator.isLoading,
        isError: (state) => state.narrator.isError,
        totalRows: (state) => state.narrator.totalRows,
        items: (state) => state.narrator.narrators,
      }),
    },
    mounted() {
      this.actionFetchNarrator();
    },
    methods: {
      ...mapActions('narrator', ['fetch', '']),
      actionFetchNarrator() {
        const payload = {
          query: this.narrator_search,
          page: this.currentPage,
          limit: this.perPage,
          
        }
        if(!this.narrator_search) {
          this.fetch(payload);
          
        } else{
          clearTimeout(this.debounce);
          this.debounce = setTimeout(() => {
            this.fetch(payload);
            console.log('this is payliad', payload);
            this.typing = false;
          
          }, 600);
        }
        return ;
        
      },
      onSearch(q) {
        this.narrator_search = q;
        this.actionFetchNarrator()
        return;
      },
      refreshPage() {
        this.currentPage = 1;
        this.narrator_search= null;
        this.actionFetchNarrator();
      },
      onClickPage(page) {
        this.currentPage = page;
        this.actionFetchNarrator();
      },
    },
    filters: {
      toLocalStringID(data = 0) {
        return data.toLocaleString('id');
      }
    }
  };
  </script>
  
  <style scoped>
  
  </style>
  